'use client';

import { useEffect } from 'react';
import Image from 'next/image';

import { datadogRum } from '@datadog/browser-rum';

import { TextV2 } from '@layr-labs/eigen-kit/react';

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    datadogRum.addError(error);
  }, [error]);

  return (
    <div className="center mt-16 flex-col gap-8 text-center">
      <h2>
        <TextV2 intent="DisplayM">An error occurred</TextV2>
      </h2>
      <div className="max-w-md">
        <TextV2>
          Something went wrong with your request. Please wait and try again. If the problem
          persists, please contact support here:{' '}
          <a
            href="https://support.eigenlayer.xyz"
            className="text-warningFg hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            support.eigenlayer.xyz
          </a>
        </TextV2>
      </div>
      <Image
        src="/logo/markABlue700.svg"
        height={40}
        width={40}
        className="justify-self-end"
        priority
        alt="eigenlayer logo"
      />
    </div>
  );
}
